<template>
  <div class="home">
    <v-list
      class="pt-0"
      flat
    >

    <div
      v-for="item in items"
      :key="item.id"
    >
      <v-list-item
        @click.stop="editItem(item.id)"
      >
        <template v-slot:default>

          <v-list-item-content>
            <v-list-item-title
            >
                {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
                Last Modified {{ item.date }}, {{ item.count }} items
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn 
              icon
            >
              <v-icon color="primary">mdi-dots-vertical</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
      </v-list-item>
      <v-divider></v-divider>
    </div>

    </v-list>

  </div>
</template>

<script>

export default {
    name: 'Home',
    data() {
        return {
            newItem: '',
            items: [
            {
                id: 1,
                title: 'joy.20201221',
                date: '12.21.2020',
                count: 3
            },
            {
                id: 2,
                title: 'joy.20201131',
                date: '11.31.2020',
                count: 1
            },
            {
                id: 3,
                title: 'jaime.20201226',
                date: '12.26.2020',
                count: 12
            }
            ]
        }
    },
    methods: {
        addItem() {
            let newItem = {
            id: Date.now(),
            title: this.newItem,
            done: false,
            qty: 1
            }
            this.items.push(newItem)
            this.newItem = ''
        },  
        editItem(id) {
            console.log( "Edit: " + id )
        }
    },
}
</script>


